import React from 'react';
import './terms.css';
function Terms() {
  return (
    <div className='termsbode'>
      <div className='container mt-5'>
        <div className="row">
          <h2 className='text-center mt-5 text-info'>Terms and Conditions</h2>
          <div className='col-lg-2 col-md-1 col-0'>{' '}</div>
          <div className='col-lg-8 col-md-10 col-12'>
            <h5 className='text-info mt-4'>Introduction</h5>
            <p className='text-light '>NeuroNest AI Pvt Ltd is a pioneering tech firm specializing in artificial intelligence solutions. With a focus on conversational AI, we craft innovative chatbots, analytics tools, and tailored AI solutions across diverse sectors. Our commitment to excellence drives us to deliver personalized, efficient, and intelligent experiences for businesses and customers. With a blend of expertise, creativity, and cutting-edge technology, we empower organizations to thrive in the digital era. website located at <span><a href='https://www.neuronestai.in/' >https://www.neuronestai.in/</a></span> and the services we provide, including our AI bot (collectively, the "Services").</p>
         
          <h5 className='text-info mt-4'>1.Acceptance of Terms</h5>
          <p className='text-light '>By accessing or using our Site and Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you must not use our Site or Services.</p>

          <h5 className='text-info mt-4'>2.Changes to Terms</h5>
          <p className='text-light'>We may modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our Site. Your continued use of the Site and Services after any changes constitutes your acceptance of the new Terms.</p>
        
          <h5 className='text-info mt-4'>3.Use of Services</h5>
          <p className='text-light '>Eligibility: You must be at least 18 years old to use our Services.</p>
          <p className='text-light '>Prohibited Activities: You agree not to use the Services for any unlawful purpose or in any way   that could harm our Company, Site, or Services. Prohibited activities include, but are not limited to
            Hacking or interfering with the Site or Services.
            Using the Services to transmit any harmful or malicious code.
            Violating any applicable laws or regulations.</p>

          <h5 className='text-info mt-4'>4.Intellectual Property</h5>
          <p className='text-light '>All content, features, and functionality on the Site and Services, including but not limited to text, graphics, logos, and software, are the exclusive property of Neuronest AI and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from our content without our express written permission.
        </p>
         <h5 className='text-info mt-4'>4.Intellectual Property</h5>
          <p className='text-light '>All content, features, and functionality on the Site and Services, including but not limited to text, graphics, logos, and software, are the exclusive property of Neuronest AI and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from our content without our express written permission.
        </p>

        <h5 className='text-info mt-4'>5.Privacy</h5>
          <p className='text-light '>Your use of the Site and Services is also governed by our Privacy Policy. By using our Site and Services, you consent to the practices described in the Privacy Policy.</p>

          <h5 className='text-info mt-4'>6.Disclaimers and Limitation of Liability</h5>
          <p className='text-light '>No Warranty: The Site and Services are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the Site or Services, including but not limited to the accuracy, reliability, or availability.
Limitation of Liability: To the fullest extent permitted by law, Neuronest AI shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site or Services.</p>
      
          <h5 className='text-info mt-4'>7.Indemnification</h5>
          <p className='text-light '>You agree to indemnify, defend, and hold harmless Neuronest AI, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Site and Services. </p>
          <h5 className='text-info mt-4'>8.Governing Law</h5>
          <p className='text-light '>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in India to resolve any dispute arising out of or relating to these Terms or the Site and Services.</p>
      

          <h5 className='text-info mt-4'>9.Payments</h5>
          <p className='text-light '>Pricing: All prices for our services and products are listed on our website and are subject to change without notice.</p>
          <p className='text-light '>Payment Methods: We accept payments via credit card, debit card, and other payment methods as indicated at checkout.</p>
          <p className='text-light '>Billing: You agree to provide accurate billing information and authorize us to charge the provided payment method for the total amount of your purchase. </p>
          <p className='text-light '> Refunds and Cancellations: Refund policies for each service and product are specified on their respective pages. Please review these policies before making a purchase. </p>
          <p className='text-light '> Payment Gateway: We use secure payment gateways to process transactions. Your payment information is encrypted and handled by third-party payment processors in compliance with industry standards. </p>

      
          </div>
          <div className='col-lg-2 col-md-1 col-0'>{" "}</div>
        </div>


      </div>
    </div>
  )
}

export default Terms