import React from 'react'
import './terms.css';
export const Privicay = () => {
  return (
    <div className='termsbode'>
      <div className='container mt-5'>
        <div className="row">
          <h2 className='text-center mt-5 text-info'>Privacy Policy</h2>
          <div className='col-lg-2 col-md-1 col-0'>{' '}</div>
          <div className='col-lg-8 col-md-10 col-12'>
            <h5 className='text-info mt-4'>Introduction</h5>
            <p className='text-light '>NeuroNest AI Pvt Ltd, Coimbatore, is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services. Please read this policy carefully.</p>

            <h5 className='text-info mt-4'>Information We Collect</h5>
            <p className='text-light '>Personal Information: We may collect personal information that you voluntarily provide to us when subscribing to our services, including your email address and payment information.</p>
            <p className='text-light '>Usage Data: We collect information about your interactions with our services, such as the features you use and the content you access.</p>
            <p className='text-light '>Device Information: We collect information about the devices you use to access our services, including the device type, operating system, and IP address.</p>


            <h5 className='text-info mt-4'>How We Use Your Information</h5>
            <p className='text-light '>Service Provision: We use your information to provide, maintain, and improve our services, including processing transactions and managing subscriptions.</p>
            <p className='text-light '>Communication: We may use your contact information to send you updates, newsletters, and other communications related to our services. You can opt-out of these communications at any time.</p>
            <p className='text-light '>Analytics: We use the information to analyze how our services are used, monitor and analyze trends, usage, and activities in connection with our services.</p>
            <p className='text-light '>Legal Compliance: We may use your information to comply with applicable laws, regulations, and legal processes.</p>

            <h5 className='text-info mt-4'>Sharing Your Information</h5>
            <p className='text-light '>Third-Party Service Providers: We may share your information with third-party service providers that perform services on our behalf, such as payment processing and data analysis.</p>
            <p className='text-light '>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
            <p className='text-light '>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</p>

            <h5 className='text-info mt-4'>Security of Your Information</h5>
            <p className='text-light '>We use administrative, technical, and physical security measures to protect your personal information. While we strive to protect your information, no method of transmission over the internet or electronic storage is 100% secure.
            </p>
            <h4 className='text-info mt-4'>Retention of Your Information</h4>
            <p className='text-light '>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected and to comply with legal, regulatory, or internal policy requirements.

            </p>

            <h5 className='text-info mt-4'>Your Rights</h5>
            <p className='text-light '>Access and Correction: You have the right to access and correct your personal information held by us.</p>
            <p className='text-light '>Data Portability: You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format.</p>
            <p className='text-light '>Deletion: You have the right to request the deletion of your personal information, subject to certain exceptions provided by law.</p>

            <h5 className='text-info mt-4'>Changes to This Privacy Policy</h5>
            <p className='text-light '>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. Your continued use of our services after any changes indicates your acceptance of the new terms.</p>

            <h5 className='text-info mt-4'>Contact Information</h5>
            <p className="text-light">
              If you have any questions about this privacy policy, please contact us at
              <span>
                <a href="mailto:info@neuronestai.in">info@neuronestai.in</a>
              </span>
            </p>
           <p className='text-light '>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in India to resolve any dispute arising out of or relating to these Terms or the Site and Services.</p>

           <h5 className='text-light '>For more details, visit our website at Neuronest AI.</h5>

             


          </div>
          <div className='col-lg-2 col-md-1 col-0'>{" "}</div>
        </div>


      </div>
    </div>
  )
}
