import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/pages/Landing pages/home";
import About from "./component/pages/Landing pages/about";
import Solution from "./component/pages/Landing pages/solution";
import Footer from "./component/pages/footer/footer";
import Industry from "./component/pages/Landing pages/industry";
import Contact from "./component/pages/contactUs/contact";
import Platform from "./component/pages/Landing pages/platform";
import HomeMarket from "./component/pages/solution pages/Marketing/home_market";
import ConvoMarket from "./component/pages/solution pages/Marketing/convo_market";
import HomeCommerce from "./component/pages/solution pages/commerce/home_commerce";
import ConvoCommerce from "./component/pages/solution pages/commerce/commerce";
import HomeSupport from "./component/pages/solution pages/support/home_support";
import ConvoSupport from "./component/pages/solution pages/support/support";
import TopNav from "./component/pages/navbar/navbar";
import HomePlatform from "./component/pages/platform/homeplatform";
import Platchannel from "./component/pages/platform/plat";
import HomeIndustry from "./component/pages/industry/home_indus";
import Indus from "./component/pages/industry/indus";
import Demo from "./component/pages/Demo/demo";
import GoToTop from "./component/pages/Landing pages/gotoTob";
import Terms from "./component/pages/privacy/terms";
import { Privicay } from "./component/pages/privacy/privicay";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={[<TopNav/>, <Home />, <About />, <Solution />, <Platform />, <Industry />, <GoToTop />, <Footer />]}
          />
          <Route path="/Conversational_Marketing" element={[<TopNav/>, <HomeMarket />, <ConvoMarket />, <GoToTop />, <Footer />]} />
          <Route path="/Conversational_Commerce" element={[<TopNav/>, <HomeCommerce />, <ConvoCommerce />, <GoToTop />, <Footer />]} />
          <Route path="/Conversational_Support" element={[<TopNav/>, <HomeSupport />, <ConvoSupport />, <GoToTop />, <Footer />]} />
          <Route path="/KRI/KPI-Solutions" element={[<TopNav/>, <HomeSupport />, <GoToTop />, <Footer />]} />
          <Route path="/platform" element={[<TopNav/>, <HomePlatform />, <Platchannel />, <GoToTop />, <Footer />]} />
          <Route path="/IndustryMain" element={[<TopNav/>, <HomeIndustry />, <Indus />, <GoToTop />, <Footer />]} />
          <Route path="/contact_us" element={[<TopNav/>, <Contact />, <GoToTop />, <Footer />]} />
          <Route path="/Book_a_Demo" element={[<TopNav/>, <Demo />, <GoToTop />, <Footer />]} />
          <Route path="/Terms_condition" element={[<TopNav/>, <Terms />]} />
          <Route path="/privacy" element={[<TopNav/>, <Privicay />]} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
